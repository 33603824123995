<template>
    <div class="rcorners1 app-nav-icons" v-if="windowSize.x > 750" :key="loading">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, props }">
                <v-btn icon small v-bind="props" v-on="on" @click="goBack" color="white">
                    <!-- <v-icon>{{ icons.mdiArrowLeft }}</v-icon> -->
                    <iconify-icon icon="solar:round-alt-arrow-left-outline"
                        style="color: white; font-size:30px"></iconify-icon>
                </v-btn>
            </template>
            Back
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, props }">
                <v-btn small icon v-bind="props" v-on="on" @click="refreshPage" color="white">
                    <!-- <v-icon>{{ icons.mdiRefresh }}</v-icon> -->
                    <iconify-icon icon="solar:refresh-circle-outline"
                        style="color: white; font-size: 30px;"></iconify-icon>
                </v-btn>
            </template>
            Refresh
        </v-tooltip>
        <PinnedRecords :listItem="false" :relatedRecord="relatedRecord" :key="relatedRecord + 'pinned'" />
    </div>

    <v-menu v-else offset-y left nudge-bottom="14" min-width="230" content-class="navigation-menu-content">
        <template v-slot:activator="{ on: onMenu, attrs }">
            <!-- <v-icon class="mr-3" color="white" v-bind="attrs" v-on="onMenu">{{ icons.mdiDotsHorizontalCircleOutline }}</v-icon> -->
            <iconify-icon icon="solar:menu-dots-circle-outline" v-bind="attrs" v-on="onMenu"
                style="color: white; font-size: 30px;"></iconify-icon>
        </template>
        <v-list style=" background-color: var(--lu-primary); opacity: 0.9; width: fit-content;">
            <v-list-item v-if="showSearch" class="mr-2  goBack" @click="goBack" style="margin-top: -1rem;">
                <!-- <v-icon class="mr-2" color="white">{{ icons.mdiArrowLeft }}</v-icon> -->
                <iconify-icon icon="solar:round-alt-arrow-left-outline"
                    style="color: white; font-size:20px"></iconify-icon>
                <div class="list-label">Back</div>
            </v-list-item>
            <v-divider v-if="showSearch" color="white" style="opacity: 0.3;"></v-divider>
            <v-list-item class="mr-2 refresh" @click="refreshPage">
                <!-- <v-icon class="mr-2" color="white">{{ icons.mdiRefresh }}</v-icon> -->
                <iconify-icon icon="solar:refresh-circle-outline" style="color: white; font-size: 20px;"></iconify-icon>
                &nbsp;
                <div class="list-label">Refresh</div>
            </v-list-item>
            <!-- <v-divider color="white" style="opacity: 0.3;"></v-divider> -->
            <v-divider color="white" style="opacity: 0.3;"></v-divider>
            <PinnedRecords :listItem="true" :relatedRecord="relatedRecord" :key="relatedRecord + 'pinned'" />
        </v-list>
    </v-menu>
</template>

<script>
import {
    mdiRefresh, mdiArrowLeft, mdiDotsHorizontalCircleOutline
} from '@mdi/js'
import { PinnedRecords } from 'aether-user-nav-actions-vue2';
import globals from '../../../globals';
import contactService from '../../../services/contact.service';
import { computed } from '@vue/composition-api';

export default {
    components: {
        PinnedRecords
    },
    setup() {
        return {
            // Icons
            icons: {
                mdiRefresh,
                mdiArrowLeft,
                mdiDotsHorizontalCircleOutline
            },
        }
    },
    data: () => ({
        windowSize: {
            x: window.innerWidth,
            y: window.innerHeight,
        },
        showSearch: false,
        statecode: 0,
        tooltipBack: false,
        tooltipRefresh: false,
        loading: false,
        relatedRecord: null
    }),
    async mounted() {
        try {
            this.loading = true
            const arr = globals.showSearchArr;
            for (var i = 0; i < arr.length; i++) {
                if (window.location.pathname.includes(arr[i])) {
                    this.showSearch = true;
                    break;
                }
                else {
                    this.showSearch = false;
                }
            };

            this.getRelatedRecord();
            this.loading = false
        } catch (err) {
            console.log(err)
        }
    },
    computed: {

    },
    methods: {
        refreshPage() {
            location.reload();
        },
        goBack() {
            history.back();
        },
        myEventHandler() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
        handleMouseOver() {
            this.tooltipBack = true;
        },
        getRelatedRecord() {
            const luidRegex = /^[L,l][0-9]{8,10}$/;
            const urlArr = window.location.pathname.split('/');
            for (var i = 0; i < urlArr.length; i++) {
                if (luidRegex.test(urlArr[i])) {
                    this.relatedRecord = urlArr[i];
                    break;
                } else if (urlArr[i].toLowerCase() === 'opportunities' && !Number.isNaN(urlArr[i + 1])) {
                    this.relatedRecord = urlArr[i + 1];
                    sessionStorage.setItem('oppId', urlArr[i + 1])
                    break;
                }
            }
        }
    },
    watch: {
        $route(to, from) {
            this.getRelatedRecord();
            const arr = globals.showSearchArr;
            for (var i = 0; i < arr.length; i++) {
                if (to.path.includes(arr[i])) {
                    this.showSearch = true;
                    return;
                }
                else {
                    this.showSearch = false;
                }
            }
            const id = window.location.pathname.split('/')[2];
        },
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    }
}
</script>

<style lang="scss" scoped>
.app-nav-icons {
    width: 130px;
    display: flex;
    justify-content: space-between;
}

.navigation-menu-content {
    background-color: var(--lu-primary);
    width: fit-content !important;
    min-width: 0 !important;
    margin-left: -1rem;
}

.list-label {
    color: white;
}

.rcorners1 {
    border-radius: 25px;
    // background: #dfdfdf;
    background-color: rgba(94, 86, 105, 0.08);
    // background-color: var(--lu-darker-light-blue);
    padding: 6px;
}

.v-app-bar ::v-deep {

    // .v-toolbar__content,
    .v-toolbar__extension {
        padding: 0px;

        .app-bar-search {
            .v-input__slot {
                padding-left: 18px;
            }
        }
    }
}

.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
    color: rgba(255, 0, 0, 0.33);
}

.boxed-container {
    // max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.searchBar {
    background-color: var(--lu-primary);
}
</style>
